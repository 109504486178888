import React, { useEffect, useState } from 'react';
import './styles/App.css';
import Header from './components/Header';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/Sentence.css';


function App() {
  const [sentences, setSentences] = useState([]);
  const [wordData, setWordData] = useState({});
  const [audioExists, setAudioExists] = useState({});
  const [currentAudio, setCurrentAudio] = useState(null);
  const [category, setCategory] = useState('Greetings'); // Default category is "Greetings"

  useEffect(() => {
    fetch('/sentences.json')
      .then(response => response.json())
      .then(data => setSentences(data.sentences))
      .catch(error => console.error('Error loading the sentences:', error));

    fetch('/words.json')
      .then(response => response.json())
      .then(data => {
        const wordMap = {};
        data.words.forEach(word => {
          wordMap[word.kh] = word;
        });
        setWordData(wordMap);
        checkAudioFiles(wordMap);
      })
      .catch(error => console.error('Error loading the words:', error));
  }, []);

  const checkAudioFiles = wordMap => {
    const audioChecks = {};
    Object.keys(wordMap).forEach(key => {
      const word = wordMap[key];
      const audio = new Audio(`/audio/words/${word.filename}`);
      audio.oncanplaythrough = () => {
        audioChecks[key] = true;
        setAudioExists(prev => ({ ...prev, ...audioChecks }));
      };
      audio.onerror = () => {
        audioChecks[key] = false;
        setAudioExists(prev => ({ ...prev, ...audioChecks }));
      };
    });
  };

  const playWordAudio = filename => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    const audio = new Audio(`/audio/words/${filename}`);
    setCurrentAudio(audio);
    audio.play().catch(error => console.error('Audio play error:', error));
  };

  const playSentenceAudio = filename => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    const audio = new Audio(`/audio/sentences/${filename}`);
    setCurrentAudio(audio);
    audio.play().catch(error => console.error('Audio play error:', error));
  };

  return (
    <Router>
      <div className="app-container">
        <Header setCategory={setCategory} category={category} />
        <div className="content-wrapper">
          {sentences
            .filter(sentence => sentence.type === category)
            .map((sentence, sIdx) => (
              <div key={sIdx} className="sentence-container">
                <div className="sentence-header">
                  <button
                    className="play-button"
                    onClick={() => playSentenceAudio(sentence.filename)}
                  >
                    <div className="play-icon"></div>
                  </button>
                  <p className="sentence-english">{sentence.english}</p>
                </div>
                <div className="words-container">
                  {sentence.words.map((wordKey, wIdx) => {
                    const word = wordData[wordKey];
                    const exists = audioExists[wordKey];
                    return word ? (
                      <div key={wIdx} className="word-card" onClick={() => playWordAudio(word.filename)}>
                        <div className="word-kh" style={{ color: exists === false ? 'red' : 'rgb(28, 48, 72)' }}>
                          {word.kh}
                        </div>
                      </div>
                    ) : (
                      <div key={wIdx} className="word-card">Loading...</div>
                    );
                  })}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Router>
  );
}

export default App;
