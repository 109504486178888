import React from 'react';
import '../styles/Header.css';

function Header({ setCategory, category }) {
  const handleCategoryChange = category => {
    setCategory(category);
    window.scrollTo(0, 0); // Scroll to the top
  };

  return (
    <header className="header-container">
      <div className="logo-container">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/1920px-Flag_of_Cambodia.svg.png"
          alt="Khmer Flag"
          className="khmer-flag"
        />
        <h1 className="header-title">
          Go Khmer - {category ? category : 'Greetings'}
        </h1>
      </div>
      <nav className="menu-container">
        <div className="dropdown">
          <button className="dropbtn">Category</button>
          <div className="dropdown-content">
            <span className="dropdown-item" onClick={() => handleCategoryChange('Greetings')}>Greetings</span>
            <span className="dropdown-item" onClick={() => handleCategoryChange('Questions')}>Questions</span>
            <span className="dropdown-item" onClick={() => handleCategoryChange('Colors')}>Colors</span>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
